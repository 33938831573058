<template>
  <v-row justify="center">
    <v-dialog
      :value="true"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-card-title>
                  Note for {{ note.vehicle }} on {{ note.date | prettyDate }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <p class="pl-4">{{ note.note }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="userPermissions.includes(13)"
                    @click="updateNote"
                    small
                    color="orange darken-2"
                  >
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Update
                  </v-btn>
                  &nbsp;
                  <v-dialog
                    v-model="dialog"
                    width="900"
                    class="pt-3 pb-3"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="userPermissions.includes(13)"
                        color="red darken-2"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left>
                          mdi-delete
                        </v-icon>
                        Delete
                      </v-btn>
                    </template>
                    <v-card class="pt-6 pb-4">
                      <v-card-text>
                        Are you sure you want to delete this note? This action cannot
                        be undone.
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="userPermissions.includes(13)"
                          small
                          color="red darken-2"
                          @click="deleteNote"
                        >
                          <v-icon left>
                            mdi-delete
                          </v-icon>
                          Confirm delete
                        </v-btn>
                        <v-btn
                          small
                          color="blue-grey lighten-4"
                          @click="dialog = false"
                        >
                          <v-icon left>
                            mdi-close
                          </v-icon>
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="editDialog"
                    width="900"
                    class="pt-3 pb-3"
                  >
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                            >
                              <v-card-title>
                                Note for {{ note.vehicle }} on {{ note.date | prettyDate }}
                              </v-card-title>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                            >
                            <v-textarea
                              solo
                              v-model="note.note"
                            >

                            </v-textarea>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              class="text-right"
                            >
                              <v-btn
                                @click="saveChanges"
                                small
                                color="orange darken-1"
                              >
                                <v-icon left>
                                  mdi-pencil
                                </v-icon>
                                Save
                              </v-btn>
                              &nbsp;
                              <v-btn
                                small
                                color="blue-grey lighten-4"
                                @click="abandonChanges"
                              >
                                <v-icon left>
                                  mdi-close
                                </v-icon>
                                Abandon Changes
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    small
                    color="blue-grey lighten-4"
                    @click="$store.commit('selectNote', 0)"
                  >
                    <v-icon left>
                      mdi-close
                    </v-icon>
                    Close
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ViewNote',
  computed: {
    token() {
      return this.$store.state.token;
    },
    note() {
      return this.$store.getters.getNote;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  data() {
    return {
      dialog: false,
      editDialog: false,
    };
  },
  methods: {
    deleteNote() {
      axios.get(`/notes/delete/${this.note.id}.json?token=${this.token}`)
        .then(() => {
          this.dialog = false;
          this.$store.commit('selectNote', 0);
          this.$store.dispatch('refreshNotes');
          this.$store.commit('jobAdded');
        });
    },
    updateNote() {
      this.editDialog = true;
    },
    saveChanges() {
      axios.post(`/notes/update.json?token=${this.token}`, this.note)
        .then(() => {
          this.dialog = false;
          this.editDialog = false;
          this.$store.commit('selectNote', 0);
          this.$store.dispatch('refreshNotes');
          this.$store.commit('jobAdded');
        });
    },
    abandonChanges() {
      this.editDialog = false;
    },
  },
};
</script>
