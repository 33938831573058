<template>
  <div class="pl-1">
    <v-row class="pt-8">
      <v-col cols="2">
        <div class="pl-2">
          <v-btn
            small
            v-if="$store.state.vehicleCountStart > 0"
            @click="$store.dispatch('prevVehicles')">
            <v-icon>
              mdi-step-backward
            </v-icon>
            Prev
          </v-btn>
          <v-btn
            small
            v-else
            disabled
          >
            <v-icon>
              mdi-step-backward
            </v-icon>
            Prev
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6">
        <span>Key:</span>&nbsp;
        <span @click="filterByType('work', true)" class="key job">Day Job</span>
        <span @click="filterByType('work', false)" class="key job">Night Job</span>
        <span
          @click="showOnHoldJobs = true"
          class="key job-on-hold">
          Job On Hold
        </span>
        <span @click="filterByType('complete')" class="key complete">Complete</span>
        <span @click="filterByType('maintenance')" class="key maintenance">Maintenance</span>
        <span @click="filterByType('notes')" class="key note">Note</span>
        <span @click="filterByType('holiday')" class="key holiday">Holiday</span>
        <span @click="filterByType('surveys')" class="key survey">Survey</span>
        <span @click="fetchEvents" class="key all">All</span>
      </v-col>
      <v-col cols="2">
        <v-btn
          v-for="n in $store.getters.vehicleCategoryLength"
          :key="n"
          x-small
          @click="$store.commit('nextVehicles', ((n - 1) * 11))">{{ n }}</v-btn>
      </v-col>
      <v-col cols="2">
        <div class="float-right pr-2">
          <v-btn
            small
            v-if="listEnd"
            disabled
          >
            Next
            <v-icon>
              mdi-step-forward
            </v-icon>
          </v-btn>
          <v-btn
            small
            v-else
            @click="$store.dispatch('nextVehicles')">
            Next
            <v-icon>
              mdi-step-forward
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="pt-1">
      <v-col cols="1">
        <v-row><v-col cols="12" class="agenda-block header">&nbsp;</v-col></v-row>
        <v-row
          v-for="n in 7"
          :key="n"
        >
          <v-col
            cols="12"
            class="agenda-block"
          >
            <h4 class="pl-2">{{ daysThisWeek[n - 1] | shortDate }}</h4>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="1"
        v-for="(vehicle, index) in categories"
        :key="index"
      >
        <v-row>
          <v-col cols="12" class="agenda-block header">
            <span class="number-plate">{{ vehicle.reg }}</span>
            <span v-if="vehicle.chargehand" class="van-occupants">
              <strong>Personnel in {{ vehicle.reg }}</strong><br />
              {{ vehicle.chargehand }}<br />{{ vehicle.worker }}<br />{{ vehicle.worker2 }}<br />
              {{ vehicle.worker3 }}
            </span>
          </v-col>
        </v-row>
        <v-row
          v-for="n in 7"
          :key="n"
        >
          <v-col
            cols="12"
            class="agenda-block"
            :data-date="n"
            :data-vehicle="vehicle.reg"
            @drop="drop"
            @dragover="allowDrop"
          >
            <span v-if="userPermissions.includes(13)">
              <v-icon
                v-if="vehicle.reg !== 'holidays'"
                class="add-job-icon-link"
                @click="addJobFromCalendar(vehicle.reg, n)"
              >
                mdi-plus-box
              </v-icon>
              <v-icon
                v-if="vehicle.reg !== 'holidays'"
                class="add-note-icon-link"
                @click="addNoteFromCalender(vehicle.reg, n)"
              >
                mdi-pencil-box
              </v-icon>
            </span>
            <div
              v-for="(filteredEvent, index) in events"
              :key="index">
              <span
                :id="filteredEvent.uniqueId"
                draggable="true"
                @dragstart="dragStart"
                @click="showEvent(filteredEvent)"
                class="event-link"
                :class="[
                  filteredEvent.eventType,
                  { onHold: filteredEvent.onHold },
                  { complete: filteredEvent.complete }
                ]"
                v-if="(filteredEvent.category === vehicle.reg) && (filteredEvent.dayNo === n)">
                {{ filteredEvent.name }}<br />
                {{ filteredEvent.client }}<br />
                <span v-if="filteredEvent.site">{{ filteredEvent.site }}</span>
                <span v-else>{{ filteredEvent.address }}</span>
                <br />
                <span v-for="(worker, index) in filteredEvent.workers" :key="index">
                  {{ worker }}
                </span>
                <br />
                <span>Value: &pound;{{ filteredEvent.value }}</span>
                <span
                  v-if="filteredEvent.eventType === 'work' && filteredEvent.eventTime === 'dayAM'"
                  class="day-night-indicator-text">AM</span>
                <span
                  v-if="filteredEvent.eventType === 'work' && filteredEvent.eventTime === 'dayPM'"
                  class="day-night-indicator-text">PM</span>
                  <v-icon
                  v-if="filteredEvent.eventType === 'work' && filteredEvent.eventTime === 'night'"
                  class="day-night-indicator">mdi-moon-waning-crescent</v-icon>
                <v-icon
                  v-if="filteredEvent.eventType === 'notes'"
                  class="day-night-indicator">mdi-pencil</v-icon>
                <v-icon
                  v-if="filteredEvent.eventType === 'maintenance'"
                  class="day-night-indicator">mdi-wrench-outline</v-icon>
                <v-icon
                  v-if="filteredEvent.eventType === 'holiday'"
                  class="day-night-indicator">mdi-umbrella-beach-outline</v-icon>
                <v-icon
                  v-if="filteredEvent.eventType === 'surveys'"
                  class="day-night-indicator">mdi-clipboard</v-icon>
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <download-csv
          :data="eventsToExport"
          :name="`jobs_week_beginning_${fileDate}.csv`">
          <v-btn small color="orange lighten-2">
            <v-icon small>mdi-table-arrow-right</v-icon>
            &nbsp;
            Export jobs w/c {{ date | prettyDate }}
          </v-btn>
        </download-csv>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="getTheVehicleChecks(true)"
          small
          color="orange lighten-2">
          <v-icon small>mdi-van-utility</v-icon>
          Unchecked Vehicles
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="modal = true"
          small
          color="orange lighten-2">
          <v-icon small>mdi-van-utility</v-icon>
          Unchecked Vehicles By Date
        </v-btn>
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="checkDate"
          persistent
          width="290px"
        >
          <v-date-picker
            v-model="checkDate"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="getTheVehicleChecks(false)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <add-job-from-calendar
      v-if="vehicleToSave"
      :vehicleToSave="vehicleToSave"
      @newJobAdded="refreshView"
      @abandonChanges="vehicleToSave = 0"
    />
    <add-note-to-save
      v-if="noteToSave"
      :noteToSave="noteToSave"
      @abandonChanges="noteToSave = null"
    />
    <on-hold-jobs
      v-if="showOnHoldJobs"
      @closeOnHoldJobs="showOnHoldJobs = false"
    />
    <vehicle-checks
      v-if="getVehicleChecks === true"
      :today="checkToday"
      :date="checkDate"
      @closeVehicleChecks="getVehicleChecks = false"
    />
  </div>
</template>

<script>
import {
  startOfWeek,
  addDays,
  format,
} from 'date-fns';
import AddJobFromCalendar from '@/components/Jobs/AddJobFromCalendar.vue';
import AddNoteToSave from '@/components/Tools/NoteToSave.vue';
import OnHoldJobs from '@/components/Jobs/OnHoldJobs.vue';
import VehicleChecks from '@/components/Vehicles/VehicleChecks.vue';
import axios from '../../axios';

export default {
  name: 'WeeklyCalendar',
  components: {
    AddJobFromCalendar,
    AddNoteToSave,
    OnHoldJobs,
    VehicleChecks,
  },
  data() {
    return {
      events: [],
      eventsToExport: [],
      vehicleToSave: null,
      noteToSave: null,
      firstStep: 0,
      showOnHoldJobs: false,
      getVehicleChecks: false,
      checkToday: true,
      checkDate: null,
      modal: false,
    };
  },
  computed: {
    jobAdded() {
      return this.$store.state.jobAdded;
    },
    date() {
      const today = this.$store.state.date;
      const firstDayOfTheWeek = startOfWeek(new Date(today));
      const chosenDate = firstDayOfTheWeek.setDate(firstDayOfTheWeek.getDate() + 1);
      return chosenDate;
    },
    fileDate() {
      return format(this.date, 'dd_MM_yyyy');
    },
    daysThisWeek() {
      const days = [];
      const today = this.$store.state.date;
      const firstDay = startOfWeek(new Date(today));
      firstDay.setDate(firstDay.getDate() + 1);
      days.push(firstDay);
      const secondDay = addDays(firstDay, 1); days.push(secondDay);
      const thirdDay = addDays(firstDay, 2); days.push(thirdDay);
      const fourthDay = addDays(firstDay, 3); days.push(fourthDay);
      const fifthDay = addDays(firstDay, 4); days.push(fifthDay);
      const sixthDay = addDays(firstDay, 5); days.push(sixthDay);
      const seventhDay = addDays(firstDay, 6); days.push(seventhDay);
      return days;
    },
    categories() {
      return this.$store.getters.vehicleCategories;
    },
    token() {
      return this.$store.state.token;
    },
    listEnd() {
      let end = false;
      const nextStart = (this.$store.state.vehicleCountStart + 11);
      if (nextStart >= this.$store.state.departmentVehiclesLength) {
        end = true;
      }
      return end;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  watch: {
    date() {
      this.fetchEvents();
    },
    jobAdded() {
      this.fetchEvents();
    },
  },
  methods: {
    getTheVehicleChecks(today) {
      this.getVehicleChecks = true;
      if (today === true) {
        this.checkDate = format(new Date(), 'yyyy-MM-dd');
      }
    },
    refreshView() {
      this.vehicleToSave = null;
      this.fetchEvents();
    },
    fetchEvents() {
      this.events = [];
      const date = format(new Date(this.date), 'yyyy-MM-dd');
      axios.get(`/plannedJobs/getWeekEvents.json?token=${this.token}&date=${date}`)
        .then((response) => {
          this.events = response.data.events;
          this.eventsToExport = response.data.eventsForExport;
          this.$store.dispatch('setWeekEvents', response.data.events);
        });
    },
    dragStart(event) {
      event.dataTransfer.setData('Text', event.target.id);
    },
    allowDrop(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      const data = event.dataTransfer.getData('Text');
      const element = document.getElementById(data);
      event.target.appendChild(element);
      const postData = {};
      postData.vehicle = event.target.dataset.vehicle;
      postData.day = event.target.dataset.date;
      postData.date = new Date(this.date);
      postData.offset = postData.date.getTimezoneOffset();
      postData.job = data;
      axios.post(`/plannedJobs/dragged.json?token=${this.token}`, postData)
        .then(() => {
          element.parentNode.removeChild(element);
          this.fetchEvents();
          this.$store.dispatch('refreshPlannedJobs');
        });
    },
    addNoteFromCalender(vehicle, day) {
      let dateToSave = new Date(this.date);
      if (day > 1) {
        dateToSave = addDays(dateToSave, (day - 1));
      }
      this.noteToSave = vehicle;
      this.$store.commit('setInitialDateArray', format(dateToSave, 'yyyy-MM-dd'));
    },
    addJobFromCalendar(vehicle, day) {
      let dateToSave = new Date(this.date);
      if (day > 1) {
        dateToSave = addDays(dateToSave, (day - 1));
      }
      this.vehicleToSave = vehicle;
      this.$store.commit('setInitialDateArray', format(dateToSave, 'yyyy-MM-dd'));
    },
    filterByType(type, day = true) {
      let events = [];
      const date = format(new Date(this.date), 'yyyy-MM-dd');
      axios.get(`/plannedJobs/getWeekEvents.json?token=${this.token}&date=${date}`)
        .then((response) => {
          events = response.data.events;
          const filtered = [];
          for (let i = 0; i < events.length; i += 1) {
            if (type === events[i].eventType) {
              if (type === 'work' && day === true) {
                if (events[i].eventTime === 'dayAM' || events[i].eventTime === 'dayPM') {
                  filtered.push(events[i]);
                }
              } else if (type === 'work' && day === false) {
                if (events[i].eventTime === 'night') {
                  filtered.push(events[i]);
                }
              } else {
                filtered.push(events[i]);
              }
            } else if (type === 'complete') {
              if (events[i].eventType === 'work' && events[i].complete === true) {
                filtered.push(events[i]);
              }
            }
          }
          this.events = filtered;
        });
    },
    showEvent(filteredEvent) {
      if (filteredEvent.plannedJobId > 0) this.$store.commit('selectPlannedJob', filteredEvent.plannedJobId);
      if (filteredEvent.maintenanceId > 0) this.$store.commit('selectVehicleMaintence', filteredEvent.maintenanceId);
      if (filteredEvent.noteId > 0) this.$store.commit('selectNote', filteredEvent.noteId);
      if (filteredEvent.holidayId > 0) this.$store.commit('setWorkerUnavailable', filteredEvent.holidayId);
      if (filteredEvent.surveyId > 0) this.$store.commit('setSurvey', filteredEvent.surveyId);
    },
  },
  mounted() {
    setTimeout(() => {
      this.fetchEvents();
    }, 300);
  },
};
</script>

<style lang="scss">
  .event-link {
    display: inline-block;
    padding: 1px 18px 1px 8px;
    border-radius: 5px;
    border: 1px solid #F0F0F0;
    cursor: pointer;
    font-size: 10px;
    max-width: 90%;
    overflow: hidden;
    position: relative;

    .day-night-indicator {
      position: absolute!important;
      top: 2px;
      right: -4px;
      font-size: 15px!important;
    }
    .day-night-indicator-text {
      position: absolute!important;
      top: 1px;
      right: 0px;
      font-size: 8px!important;
      font-style: italic;
    }

    &.work {
      background-color: #FFE0B2;

      &:hover,
      &:focus {
        background-color: #FFB74D;
      }

      &.onHold {
        background-color: #2196F3;

        &:hover,
        &:focus {
          background-color: #1976D2;
        }
      }

      &.complete {
        background-color: #FB3434;

        &:hover,
        &:focus {
          background-color: #FF0000;
        }
      }
    }

    &.maintenance {
      background-color: #FFCDD2;

      &:hover,
      &:focus {
        background-color: #E57373;
      }
    }

    &.notes {
      background-color: #B2EBF2;

      &:hover,
      &:focus {
        background-color: #4DD0E1;
      }
    }

    &.holiday {
      background-color: #F0F4C3;

      &:hover,
      &:focus {
        background-color: #DCE775;
      }
    }

    &.surveys {
      background-color: #EA80FC;

      &:hover,
      &:focus {
        background-color: #D500F9;
      }
    }
  }

  .agenda-block {
    position: relative;
    height: 120px;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    overflow-x: hidden;
    overflow-y: auto;

    &.header {
      height: 50px;
      text-align: center;
    }
  }

  .add-job-icon-link {
    position:absolute!important;
    top: 10px;
    right: 0;
    cursor: pointer;
    color: #FFB74D!important;
    caret-color: #FFB74D!important;

    &:hover {
      color: #F57C00!important;
      caret-color: #F57C00!important;
    }
  }

  .add-note-icon-link {
    position:absolute!important;
    top: 30px;
    right: 0px;
    cursor: pointer;
    color: #00BCD4!important;
    caret-color: #00BCD4!important;

    &:hover {
      color: #0097A7!important;
      caret-color: #0097A7!important;
    }
  }

  .key {
    display: inline-block;
    padding: 3px;
    margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;

    &.job {
      background-color: #FFE0B2;
      &:hover, &:focus {
        background-color: #FFB74D;
      }
    }
    &.complete {
      background-color: #FB3434;
      &:hover, &:focus {
        color: #333;
        background-color: #FF0000;
      }
    }
    &.job-on-hold {
      background-color: #2196F3;
      &:hover, &:focus {
        color: white;
        background-color: #0288D1
      }
    }
    &.note {
      background-color: #B2EBF2;
      &:hover, &:focus {
        background-color: #4DD0E1;
      }
    }
    &.maintenance {
      background-color: #FFCDD2;
      &:hover, &:focus {
        background-color: #E57373;
      }
    }
    &.holiday {
      background-color: #F0F4C3;
      &:hover, &:focus {
        background-color: #DCE775;
      }
    }
    &.survey {
      background-color: #EA80FC;
      &:hover, &:focus {
        background-color: #D500F9;
      }
    }
    &.all {
      background-color: #CFD8DC;
      &:hover, &:focus {
        color: white;
        background-color: #90A4AE;
      }
    }
  }

  .number-plate {
    display: inline-block;
    padding: 0 4px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: Helvetica, Arial, Sans;
    border: 2px solid #000;
    background-color: #e6c200;
    height: 24px;
    line-height: 24px;
    position: relative;
    left: -4px;
    cursor: pointer;
  }
  .van-occupants {
    display: none;
    position: fixed;
    top: 100px;
    left: 50%;
    margin-left: -200px;
    width: 400px;
    background-color: #FFF;
    padding: 20px;
    border-radius: 3px;
    border: 1px solid #333;
    z-index: 99;
  }
  .number-plate:hover + .van-occupants {
    display: block;
  }

</style>
